var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"items":_vm.computedList,"fields":_vm.fields,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"sorter":"","details":""},scopedSlots:_vm._u([{key:"Serial",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.Serial ?? 'N/A')+" ")])]}},{key:"Quantity",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatNumber(item.Quantity, 2))+" ")])]}},{key:"Weigth",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatNumber(item.Weigth, 2))+" ")])]}},{key:"Volumen",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatNumber(item.Volumen, 2))+" ")])]}},{key:"SquareMeters",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatNumber(item.SquareMeters, 2))+" ")])]}},{key:"options",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
            content: `${_vm.$t('label.see')} ${_vm.$t('label.serial')}`,
            placement: 'top-start'
          }),expression:"{\n            content: `${$t('label.see')} ${$t('label.serial')}`,\n            placement: 'top-start'\n          }"}],attrs:{"square":"","size":"sm","color":"watch"},on:{"click":function($event){return _vm.ViewSerialJson(item)}}},[_c('CIcon',{attrs:{"name":"eye"}})],1)],1)]}},{key:"details",fn:function({item}){return [(item?.SerialJson && item.SerialJson.length != 0)?_c('CCollapse',{staticClass:"p-2",attrs:{"show":Boolean(item?.FgCollapse)}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"column-filter":"","items":_vm.computedSerialList(item.SerialJson),"fields":_vm.fieldSerial,"table-filter":_vm.tableText.tableFilterText,"noItemsView":_vm.tableText.noItemsViewText,"items-per-page":_vm.tableText.itemsPerPage},scopedSlots:_vm._u([{key:"Serial",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(`${item?.Serial ?? 'N/A'} ${item.TpCargoDetailCode ? `(${item.TpCargoDetailCode})` : ''}`.trim())+" ")])]}},{key:"Quantity",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatNumber(item?.Quantity, 2))+" ")])]}},{key:"Weigth",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatNumber(item?.Weigth, 2))+" ")])]}},{key:"Volumen",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.formatNumber(item?.Volumen, 2))+" ")])]}},{key:"options",fn:function({ item }){return [_c('td',{staticClass:"text-center"},[(_vm.ValidateContainer(item.PackagingId))?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                  content: _vm.$t('label.ViewContainer'),
                  placement: 'top-start'
                }),expression:"{\n                  content: $t('label.ViewContainer'),\n                  placement: 'top-start'\n                }"}],attrs:{"square":"","size":"sm","color":"watch"},on:{"click":function($event){return _vm.ViewContainer(item)}}},[_c('CIcon',{attrs:{"name":"cil-list"}})],1):_vm._e()],1)]}}],null,true)})],1):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }